import React from "react";
import { MoonLoader } from "react-spinners";
// bg - transparent;
const Loader = ({ color = "blue", loading = false }) => {
  return (
    <div
      className="d-flex items-center justify-center opacity-95 "
      style={{
        width: "100%",
        height: "100vh",
        position: "fixed",
        zIndex: 5000,
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MoonLoader
        color={color}
        loading={loading}
        cssOverride={{
          display: "flex",
          margin: "0 auto",
          borderColor: "red",
          zIndex: 5001,
        }}
      />
    </div>
  );
};

export default Loader;
