import React, { useEffect, useState } from "react";
import useHttp from "../../../Hooks/useHttp";
import SelectField from "components/fields/SelectField";
import Pagination from "components/Pagination/Pagination";
import routes from "routes";
import GamePlayDetails from "./components/GamePlayDetails"; // Import the GamePlayDetails component
import androidImage from "../../../icons/android.png";
import computerImage from "../../../icons/computer.png";
import { useLocation } from "react-router-dom";

const PlayerHistoryTables = () => {
  const location = useLocation();
  const Data = sessionStorage.getItem("user");
  const { userId, isFromDashboard, selectedFromDate, selectedToDate } =
    location.state || {};
  const [loading, setLoading] = useState(false);
  const [LoginUser] = useState(JSON.parse(Data));
  const { PostRequest } = useHttp();
  const [TableData, setTableData] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null); // State for selected player
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [selectedFromDateDay, setSelectedFromDateDay] = useState("");
  const [selectedFromDateMonth, setSelectedFromDateMonth] = useState("");
  const [selectedFromDateYear, setSelectedFromDateYear] = useState("");
  const [selectedGameName, setSelectedGameName] = useState("");
  useEffect(() => {
    const currentDate = new Date();
    let fromDate = selectedFromDate ? new Date(selectedFromDate) : currentDate;
    let toDate = selectedToDate ? new Date(selectedToDate) : currentDate;
    const fromDay = fromDate.getDate();
    const fromMonth = fromDate.getMonth() + 1; // Month is zero-based, so add 1
    const fromYear = fromDate.getFullYear();
    const toDay = toDate.getDate();
    const toMonth = toDate.getMonth() + 1; // Month is zero-based, so add 1
    const toYear = toDate.getFullYear();
    setSelectedFromDateDay(fromDay.toString());
    setSelectedFromDateMonth(fromMonth.toString());
    setSelectedFromDateYear(fromYear.toString());
    setSelectedDay(toDay.toString());
    setSelectedMonth(toMonth.toString());
    setSelectedYear(toYear.toString());
    setSelectedGameName("Today");
    setCurrentYear(toYear.toString());
  }, [selectedFromDate, selectedToDate]);
  const handleDateOptionChange = (option) => {
    const currentDate = new Date();
    let fromDateDay = "";
    let fromDateMonth = "";
    let fromDateYear = "";
    let toDateDay = "";
    let toDateMonth = "";
    let toDateYear = "";

    switch (option) {
      case "Today":
        fromDateDay = currentDate.getDate().toString();
        fromDateMonth = (currentDate.getMonth() + 1).toString();
        fromDateYear = currentDate.getFullYear().toString();
        toDateDay = fromDateDay;
        toDateMonth = fromDateMonth;
        toDateYear = fromDateYear;
        break;
      case "Yesterday":
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
        fromDateDay = yesterday.getDate().toString();
        fromDateMonth = (yesterday.getMonth() + 1).toString();
        fromDateYear = yesterday.getFullYear().toString();
        toDateDay = fromDateDay;
        toDateMonth = fromDateMonth;
        toDateYear = fromDateYear;
        break;
      case "This Week":
        const firstDayOfWeek = new Date(
          currentDate.setDate(currentDate.getDate() - currentDate.getDay())
        );
        const lastDayOfWeek = new Date(
          currentDate.setDate(currentDate.getDate() + 6)
        );
        fromDateDay = firstDayOfWeek.getDate().toString();
        fromDateMonth = (firstDayOfWeek.getMonth() + 1).toString();
        fromDateYear = firstDayOfWeek.getFullYear().toString();
        toDateDay = lastDayOfWeek.getDate().toString();
        toDateMonth = (lastDayOfWeek.getMonth() + 1).toString();
        toDateYear = lastDayOfWeek.getFullYear().toString();
        break;
      case "Last Week":
        const previousWeekStart = new Date(
          currentDate.setDate(currentDate.getDate() - currentDate.getDay() - 7)
        );
        const previousWeekEnd = new Date(
          currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6)
        );
        fromDateDay = previousWeekStart.getDate().toString();
        fromDateMonth = (previousWeekStart.getMonth() + 1).toString();
        fromDateYear = previousWeekStart.getFullYear().toString();
        toDateDay = previousWeekEnd.getDate().toString();
        toDateMonth = (previousWeekEnd.getMonth() + 1).toString();
        toDateYear = previousWeekEnd.getFullYear().toString();
        break;
      case "This Month":
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );
        fromDateDay = firstDayOfMonth.getDate().toString();
        fromDateMonth = (firstDayOfMonth.getMonth() + 1).toString();
        fromDateYear = firstDayOfMonth.getFullYear().toString();
        toDateDay = lastDayOfMonth.getDate().toString();
        toDateMonth = (lastDayOfMonth.getMonth() + 1).toString();
        toDateYear = lastDayOfMonth.getFullYear().toString();
        break;
      default:
        break;
    }

    setSelectedFromDateDay(fromDateDay);
    setSelectedFromDateMonth(fromDateMonth);
    setSelectedFromDateYear(fromDateYear);
    setSelectedDay(toDateDay);
    setSelectedMonth(toDateMonth);
    setSelectedYear(toDateYear);
  };
  // Helper function to format date components into YYYY-MM-DD format
  let startDate = "";
  let endDate = "";
  // Function to combine individual date components into a single date string
  const combineDateComponents = (year, month, day) => {
    const paddedMonth = month < 10 ? `0${month}` : `${month}`;
    const paddedDay = day < 10 ? `0${day}` : `${day}`;
    // return `${paddedDay}-${paddedMonth}-${year}`;
    return `${year}-${paddedMonth}-${paddedDay}`;
  };
  startDate = combineDateComponents(
    selectedFromDateYear,
    selectedFromDateMonth,
    selectedFromDateDay
  );
  endDate = combineDateComponents(selectedYear, selectedMonth, selectedDay);

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const API = process.env.REACT_APP_API_URL;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalPage, setTotalPage] = useState(0);

  const getPlayerHistory = async () => {
    var STO = "";
    var Agent = "";
    var Player = "";
    var username = "";
    if (LoginUser?.userType === "STO") {
      STO = LoginUser?.id;
    }
    if (LoginUser?.userType === "Agent") {
      Agent = LoginUser?.id;
    }
    if (LoginUser?.userType === "Player") {
      Player = LoginUser?.id;
    }
    setLoading(true);
    if (startDate === "-0-0" && endDate === "-0-0") {
      if (selectedFromDate && selectedToDate) {
        endDate = selectedToDate;
        startDate = selectedFromDate;
      } else {
        const today = new Date();
        endDate = getFormattedDate(today);
        startDate = endDate;
      }
    }

    const { data } = await PostRequest(API + "users/GameHistoryAdmin", {
      userId: userId,
      page,
      pageSize,
      STO,
      Agent,
      Player,
      username,
      startDate,
      endDate,
    });
    setLoading(false);
    if (data.responseCode === 1) {
      if (isFromDashboard) {
        // Filter data to include only rows with ticket time as today's date
        const todayIST = new Date().toLocaleDateString("en-US", {
          timeZone: "Asia/Kolkata",
        });
        const filteredData = data.data.filter((row) => {
          const ticketTimeIST = new Date(row.ticketTime).toLocaleDateString(
            "en-US",
            { timeZone: "Asia/Kolkata" }
          );
          return ticketTimeIST === todayIST;
        });
        setTotalPage(Math.ceil(filteredData.length / pageSize));
        setTableData(filteredData);
      } else {
        setTotalPage(Math.ceil(data.data.length / pageSize));
        setTableData(data.data);
      }
    }
  };
  const [currentRoute, setCurrentRoute] = useState("");
  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };

  useEffect(() => {
    getPlayerHistory();
  }, [page, pageSize, LoginUser, selectedFromDate, selectedToDate]);

  useEffect(() => {
    getActiveRoute(routes);
  }, []);

  const handleDrawIdClick = (player) => {
    setSelectedPlayer(player); // Set the selected player data
  };
  // check the fields (draw time, claim point, unclaim point, )

  return (
    <div className="table-responsive rounded-[12px] bg-white p-[10px]">
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-1">
        {!isFromDashboard && (
          <div className=" bg-white" style={{ zIndex: 100 }}>
            {!selectedPlayer && (
              <div className="relative mb-5 flex items-center justify-between pt-[20px]">
                <div className="w-full bg-blue-500 p-2 text-xl font-bold text-white">
                  {currentRoute}
                </div>
              </div>
            )}
            {
              <div className="mb-3 space-y-0">
                {!selectedPlayer && (
                  <div className="mr-3 ml-52 flex w-full items-center align-top">
                    <label
                      htmlFor="Stokez"
                      className="text-black-700 mt-2 mr-2 block text-sm font-black"
                    >
                      Select Stokez:
                    </label>
                    <SelectField
                      style={{
                        marginTop: "10px",
                        borderColor: "black",
                        marginBottom: "5px",
                        height: "30px",
                        borderRadius: 0,
                        padding: "0 5px",
                      }}
                      variant="auth"
                      id="Stokez"
                      extra=""
                      label=""
                      onChange={(e) => {}}
                    >
                      <option value="">Select Stokez</option>
                    </SelectField>
                  </div>
                )}
                {!selectedPlayer && (
                  <div
                    style={{ marginLeft: "213px" }}
                    className="flex w-full items-center align-top"
                  >
                    <label
                      htmlFor="GameName"
                      className="text-black-700 mt-2 mr-2 block text-sm font-black"
                    >
                      Game Name:
                    </label>
                    <SelectField
                      style={{
                        borderColor: "black",
                        marginTop: "10px",
                        marginBottom: "5px",
                        height: "30px",
                        width: "500px",
                        borderRadius: 0,
                        padding: "0 5px",
                      }}
                      variant="auth"
                      id="GameName"
                      extra=""
                      label=""
                      onChange={(e) => {}}
                    >
                      <option value="">Triple Chance</option>
                    </SelectField>
                  </div>
                )}
                {!selectedPlayer && (
                  <div
                    style={{ marginLeft: "227px" }}
                    className="flex w-full items-center align-top"
                  >
                    <label
                      htmlFor="FromDate"
                      className="text-black-700 mt-2 mr-2 block text-sm font-black"
                    >
                      From Date:
                    </label>
                    <div className="flex space-x-2 align-top">
                      <SelectField
                        style={{
                          padding: "0 5px",
                          marginTop: "10px",
                          borderColor: "black",
                          marginBottom: "5px",
                          height: "30px",
                          width: "100px",
                          borderRadius: 0,
                        }}
                        id="fromDateDay"
                        variant="auth"
                        value={selectedFromDateDay}
                        onChange={(e) => setSelectedFromDateDay(e.target.value)}
                      >
                        {[...Array(31)].map((_, index) => (
                          <option
                            key={index + 1}
                            value={(index + 1).toString()}
                          >
                            {index + 1}
                          </option>
                        ))}
                      </SelectField>
                      <SelectField
                        style={{
                          padding: "0 5px",
                          marginTop: "10px",
                          borderColor: "black",
                          marginBottom: "5px",
                          height: "30px",
                          width: "100px",
                          borderRadius: 0,
                        }}
                        id="ToDateMonth"
                        variant="auth"
                        value={selectedFromDateMonth}
                        onChange={(e) =>
                          setSelectedFromDateMonth(e.target.value)
                        }
                      >
                        {[...Array(12)].map((_, index) => (
                          <option
                            key={index + 1}
                            value={(index + 1).toString()}
                          >
                            {index + 1}
                          </option>
                        ))}
                      </SelectField>
                      <SelectField
                        style={{
                          padding: "0 5px",
                          marginTop: "10px",
                          borderColor: "black",
                          marginBottom: "5px",
                          height: "30px",
                          width: "100px",
                          borderRadius: 0,
                        }}
                        id="ToDateYear"
                        variant="auth"
                        value={selectedFromDateYear}
                        onChange={(e) =>
                          setSelectedFromDateYear(e.target.value)
                        }
                      >
                        {[...Array(25)].map((_, index) => {
                          const year = currentYear - index;
                          return (
                            <option key={year} value={year.toString()}>
                              {year}
                            </option>
                          );
                        })}
                      </SelectField>
                      <SelectField
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderColor: "black",
                          marginTop: "10px",
                          marginBottom: "5px",
                          height: "30px",
                          width: "100px",
                          borderRadius: 0,
                          padding: "0 5px",
                        }}
                        variant="auth"
                        id="GameName"
                        extra=""
                        label=""
                        onChange={(e) => {
                          setSelectedGameName(e.target.value);
                          handleDateOptionChange(e.target.value);
                        }}
                        value={selectedGameName}
                      >
                        <option value="Today">Today</option>
                        <option value="Yesterday">Yesterday</option>
                        <option value="This Week">This Week</option>
                        <option value="Last Week">Last Week</option>
                        <option value="This Month">This Month</option>
                      </SelectField>
                    </div>
                  </div>
                )}
                {!selectedPlayer && (
                  <div
                    style={{ marginLeft: "229px" }}
                    className="flex w-full items-center align-top"
                  >
                    <label
                      htmlFor="ToDate"
                      className="text-black-700 mt-2 ml-4 mr-2 block text-sm font-black"
                    >
                      To Date:
                    </label>
                    <div className="flex space-x-2">
                      <SelectField
                        style={{
                          padding: "0 5px",
                          borderColor: "black",
                          marginTop: "10px",
                          marginBottom: "5px",
                          height: "30px",
                          width: "100px",
                          borderRadius: 0,
                        }}
                        id="ToDateDay"
                        variant="auth"
                        value={selectedDay}
                        onChange={(e) => setSelectedDay(e.target.value)}
                      >
                        {[...Array(31)].map((_, index) => (
                          <option
                            key={index + 1}
                            value={(index + 1).toString()}
                          >
                            {index + 1}
                          </option>
                        ))}
                      </SelectField>
                      <SelectField
                        style={{
                          padding: "0 5px",
                          borderColor: "black",
                          marginTop: "10px",
                          marginBottom: "5px",
                          height: "30px",
                          width: "100px",
                          borderRadius: 0,
                        }}
                        id="ToDateMonth"
                        variant="auth"
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                      >
                        {[...Array(12)].map((_, index) => (
                          <option
                            key={index + 1}
                            value={(index + 1).toString()}
                          >
                            {index + 1}
                          </option>
                        ))}
                      </SelectField>
                      <SelectField
                        style={{
                          padding: "0 5px",
                          borderColor: "black",
                          marginTop: "10px",
                          marginBottom: "5px",
                          height: "30px",
                          width: "100px",
                          borderRadius: 0,
                        }}
                        id="ToDateYear"
                        variant="auth"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                      >
                        {[...Array(25)].map((_, index) => {
                          const year = currentYear - index;
                          return (
                            <option key={year} value={year.toString()}>
                              {year}
                            </option>
                          );
                        })}
                      </SelectField>
                    </div>
                  </div>
                )}
              </div>
            }
            <div className="mt-3 flex gap-5">
              {!selectedPlayer && (
                <button
                  type="button"
                  onClick={() => getPlayerHistory()}
                  style={{
                    marginLeft: "304px",
                    color: "white",
                    borderRadius: 5,
                    backgroundColor: "#30A5FF",
                  }}
                  className="mb-8 flex h-10 items-center justify-center rounded-xl px-4 outline-none"
                >
                  Search
                </button>
              )}
            </div>
          </div>
        )}

        {!selectedPlayer && (
          <div className="p-4">
            <table className="min-w-full border border-gray-200 bg-white">
              <thead>
                <tr>
                  <th className="border px-4 py-2 font-black text-[#57AAFF]">
                    S.No.
                  </th>
                  <th className="border px-4 py-2 font-black text-[#57AAFF]">
                    Game Id
                  </th>
                  <th className="border px-4 py-2 font-black text-[#57AAFF]">
                    Ticket Id
                  </th>
                  <th className="border px-4 py-2 font-black text-[#57AAFF]">
                    Ticket Time
                  </th>
                  {/* <th className="px-4 py-2 border text-[#57AAFF] font-black">Draw Time</th> */}
                  <th className="border px-4 py-2 font-black text-[#57AAFF]">
                    Username
                  </th>
                  <th className="border px-4 py-2 font-black text-[#57AAFF]">
                    Game
                  </th>
                  <th className="border px-2 py-2 font-black text-[#57AAFF]">
                    Start Point
                  </th>
                  <th className="border px-2 py-2 font-black text-[#57AAFF]">
                    Bet
                  </th>
                  <th className="border px-2 py-2 font-black text-[#57AAFF]">
                    Won
                  </th>
                  <th className="border px-2 py-2 font-black text-[#57AAFF]">
                    End Point
                  </th>
                  <th className="border px-2 py-2 font-black text-[#57AAFF]">
                    Claim Point
                  </th>
                  <th className="border px-2 py-2 font-black text-[#57AAFF]">
                    Unclaim Point
                  </th>
                  <th className="border px-2 py-2 font-black text-[#57AAFF]">
                    Cancelled
                  </th>
                  <th className="border px-2 py-2 font-black text-[#57AAFF]">
                    Bonus Spin
                  </th>
                </tr>
              </thead>
              <tbody>
                {TableData.map((player, index) => (
                  <tr key={index} className="even:bg-gray-100">
                    <td className="border px-4 py-2 text-center">
                      {index + 1}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      <button
                        onClick={() => handleDrawIdClick(player)}
                        className="text-[#57AAFF] hover:underline"
                      >
                        {player.gameId}
                      </button>
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {player.ticketId}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {new Date(player.ticketTime).toLocaleString("en-IN", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}{" "}
                      {new Date(player.ticketTime).toLocaleTimeString("en-IN", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      <div className="flex items-center justify-center">
                        <span>{player.userId}</span>
                        {player.deviceType === "Desktop" && (
                          <img
                            src={computerImage}
                            alt="Desktop"
                            className="ml-2 h-6 w-6"
                          />
                        )}
                        {player.deviceType === "Android" && (
                          <img
                            src={androidImage}
                            alt="Android"
                            className="ml-2 h-6 w-6"
                          />
                        )}
                      </div>
                    </td>
                    <td className="m-16 w-16 border px-4 py-2 text-center">
                      JEETO JOKER
                    </td>
                    {/* <td className="px-4 py-2 border text-center">
                                                {new Date(player.ticketTime).toLocaleString('en-IN', {
                                                    month: 'short',
                                                    day: 'numeric',
                                                    year: 'numeric',
                                                })}
                                                {' '}
                                                {new Date(player.ticketTime).toLocaleTimeString('en-IN', {
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    hour12: true,
                                                })}
                                            </td> */}
                    <td className="border px-2 py-2 text-center">
                      {player.startPoint}
                    </td>
                    <td className="border px-2 py-2 text-center">
                      {player.betPoint}
                    </td>
                    <td className="border px-2 py-2 text-center">
                      {player.winpoint}
                    </td>
                    <td className="border px-2 py-2 text-center">
                      {player.endPoint}
                    </td>
                    <td className="border px-2 py-2 text-center">
                      {player.winpoint}
                    </td>
                    <td className="border px-2 py-2 text-center">
                      {player.unclaimPoint}
                    </td>
                    <td className="border px-2 py-2 text-center">
                      {player.isCanceled === false ? "NO" : "YES"}
                    </td>
                    <td className="border px-2 py-2 text-center">
                      {player.power}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {selectedPlayer && (
          <>
            <GamePlayDetails player={selectedPlayer} />
          </>
        )}
        <Pagination
          currentPage={page}
          totalPage={TotalPage}
          onPageChange={(newPage) => setPage(newPage)}
        />
      </div>
    </div>
  );
};

export default PlayerHistoryTables;
