import React from 'react';
import 'tailwindcss/tailwind.css';
import s from '../../../../icons/s.png';
import l from '../../../../icons/l.png';
import c from '../../../../icons/c.png';
import d from '../../../../icons/d.png';
import j from '../../../../icons/j.png';
import k from '../../../../icons/k.png';
import q from '../../../../icons/q.png';

const BetDetails = ({ data }) => {
    const { j1, j2, j3, j4, k1, k2, k3, k4, q1, q2, q3, q4, winCart, winpoint } = data;

    // Map card codes to images and values
    const cards = [
        { key: 'j1', value: j1, images: [j, l], },
        { key: 'j2', value: j2, images: [j, s], },
        { key: 'j3', value: j3, images: [j, d], },
        { key: 'j4', value: j4, images: [j, c], },

        { key: 'k1', value: k1, images: [k, l], },
        { key: 'k2', value: k2, images: [k, s], },
        { key: 'k3', value: k3, images: [k, d], },
        { key: 'k4', value: k4, images: [k, c], },

        { key: 'q1', value: q1, images: [q, l], },
        { key: 'q2', value: q2, images: [q, s], },
        { key: 'q3', value: q3, images: [q, d], },
        { key: 'q4', value: q4, images: [q, c], }
    ];

    // Filter cards with values greater than 0
    const filteredCards = cards.filter(card => card.value > 0);

    return (
        <div className="p-4">
            <div className="h-15 mb-0" style={{ backgroundColor: '#30A5FF' }}>
                <h2 style={{ color: 'white' }} className="text-2xl font-semibold mb-0">Bet Details</h2>
            </div>
            <table className="min-w-full bg-white border border-gray-200 mt-0">
                <thead>
                    <tr>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>S.NO</th>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>Bet</th>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>Positions</th>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>Amount</th>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>Win</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredCards.map((card, index) => (
                        <tr className="even:bg-gray-100" key={card.key}>
                            <td className="px-4 py-2 border text-center">{index + 1}</td>
                            <td className="px-4 py-2 border text-center">1</td>
                            <td className="px-4 py-2 border text-center flex justify-center items-center">
                                <img src={card.images[0]} alt={card.label} className="w-6 h-6 mr-1" />
                                <img src={card.images[1]} alt={card.label} className="w-6 h-6 mr-1" />
                                {card.label}
                            </td>
                            <td className="px-4 py-2 border text-center">{card.value}</td>
                            <td className="px-4 py-2 border text-center">{winCart === card.key ? winpoint : 0}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BetDetails;
