import React from "react";

const NotFound = () => {
  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="text-center">
        <h1 className="mb-4 text-5xl font-bold text-gray-800">404</h1>
        <p className="mb-8 text-lg text-gray-600">Page Not Found</p>
        <img
          src="../assets/Sad-Face-Emoji.png"
          alt="Sad Emoji"
          className="mx-auto mb-4 h-56p w-56p"
        />
        <p className="text-gray-700">
          Oops! It seems like the page you are looking for does not exist.
        </p>
        <a
          href="/"
          className="mt-4 inline-block rounded-full bg-blue-500 px-4 py-2 text-white transition duration-300 hover:bg-blue-600"
        >
          Go to Home
        </a>
      </div>
    </div>
  );
};

export default NotFound;
