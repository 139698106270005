import React from "react";

const Pagination = ({ totalPage, current, setPage }) => {
  const maxPagesToShow = 1; // Adjust this number based on how many page numbers you want to show

  const getPages = () => {
    // const totalPages = Math.min(totalPage, maxPagesToShow);
    const startPage = Math.max(1, current - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

    const pages = [];
    if (current > 1) {
      pages.push(
        <li>
          <p
            onClick={() => setPage(current - 1)}
            className="flex h-8 cursor-pointer items-center justify-center border border-gray-300 bg-white px-4 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Previous
          </p>
        </li>
      );
    }
    if (startPage > 1) {
      pages.push(
        <li key={1}>
          <p
            onClick={() => setPage(1)}
            className="flex h-8 cursor-pointer items-center justify-center border border-gray-300 bg-white px-4 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            1
          </p>
        </li>
      );
      if (startPage > 2) {
        pages.push(
          <li key="ellipsis-start">
            <span className="flex h-8 items-center justify-center px-4">
              ...
            </span>
          </li>
        );
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <li key={page}>
          <p
            onClick={() => setPage(page)}
            aria-current={page === current ? "page" : undefined}
            className={`flex h-8 cursor-pointer items-center justify-center border border-gray-300 px-4 leading-tight ${
              page === current
                ? "bg-blue-50 text-blue-600"
                : "bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            }`}
          >
            {page}
          </p>
        </li>
      );
    }

    if (endPage < totalPage) {
      if (endPage < totalPage - 1) {
        pages.push(
          <li key="ellipsis-end">
            <span className="flex h-8 items-center justify-center px-4">
              ...
            </span>
          </li>
        );
      }
      pages.push(
        <li key={totalPage}>
          <p className="flex h-8 cursor-pointer items-center justify-center border border-gray-300 bg-white px-4 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            {totalPage}
          </p>
        </li>
      );
    }
    if (current < totalPage) {
      pages.push(
        <li>
          <p
            onClick={() => setPage(current + 1)}
            className="flex h-8 cursor-pointer items-center justify-center border border-gray-300 bg-white px-4 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Next
          </p>
        </li>
      );
    }

    return pages;
  };

  return (
    <div className="w-full">
      <nav className=" align-self-end">
        <ul className="inline-flex h-8 -space-x-px self-end text-base">
          <li>
            <p
              onClick={() => setPage(1)}
              className="flex h-8 cursor-pointer items-center justify-center border border-gray-300 bg-white px-4 leading-tight text-gray-500 ms-0 rounded-s-lg border-e-0 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              First
            </p>
          </li>
          {getPages()}
          <li>
            <p
              onClick={() => setPage(totalPage)}
              className="flex h-8 cursor-pointer items-center justify-center border border-gray-300 bg-white px-4 leading-tight text-gray-500 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              Last
            </p>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
