import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Use this if you're using React Router

const AutoLogout = () => {
  const [remainingTime, setRemainingTime] = useState(300); // 5 minutes in seconds
  const navigate = useNavigate(); // React Router hook for navigation

  let activityTimeout; // Timeout to track user inactivity
  let countdownInterval; // Interval to update the countdown

  useEffect(() => {
    // Reset timers on user activity
    const handleUserActivity = () => {
      resetTimers();
    };

    // Attach event listeners for activity
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("click", handleUserActivity);

    // Start the timers
    startTimers();

    // Cleanup event listeners and timers on unmount
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
      clearTimers();
    };
  }, []);

  // Start inactivity timer and countdown
  const startTimers = () => {
    setRemainingTime(300); // Reset countdown to 5 minutes

    // Start countdown timer
    countdownInterval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearTimers();
          logoutUser(); // Trigger logout when time reaches 0
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Set inactivity timeout
    activityTimeout = setTimeout(() => {
      clearTimers();
      logoutUser(); // Trigger logout after 5 minutes of inactivity
    }, 300000); // 5 minutes in milliseconds
  };

  // Reset timers on user activity
  const resetTimers = () => {
    clearTimers();
    startTimers();
  };

  // Clear all timers
  const clearTimers = () => {
    clearTimeout(activityTimeout);
    clearInterval(countdownInterval);
  };

  // Logout user
  const logoutUser = () => {
    localStorage.clear();
    sessionStorage.clear(); // Clear session storage
    navigate("/login"); // Redirect to login page
  };

  return (
    <div style={{ textAlign: "center", fontSize: 10 }}>
      <h1>Timeout</h1>
      <h2>Remaining: {formatTime(remainingTime)}</h2>
      <p>You will be logged out after 5 minutes of inactivity.</p>
    </div>
  );
};

// Helper function to format time in MM:SS format
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${secs
    .toString()
    .padStart(2, "0")}`;
};

export default AutoLogout;
