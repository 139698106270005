import React, { useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';
import j_c from '../../../../icons/j_c.png';
import j_d from '../../../../icons/j_d.png';
import j_l from '../../../../icons/j_l.png';
import j_s from '../../../../icons/j_s.png';
import k_c from '../../../../icons/k_c.png';
import k_d from '../../../../icons/k_d.png';
import k_l from '../../../../icons/k_l.png';
import k_s from '../../../../icons/k_s.png';
import q_c from '../../../../icons/q_c.png';
import q_d from '../../../../icons/q_d.png';
import q_l from '../../../../icons/q_l.png';
import q_s from '../../../../icons/q_s.png';
import useHttp from '../../../../Hooks/useHttp';
import BetDetails from './BetDetails';

const cardImageMap = {
    j1: j_l,
    j2: j_s,
    j3: j_d,
    j4: j_c,
    k1: k_l,
    k2: k_s,
    k3: k_d,
    k4: k_c,
    q1: q_l,
    q2: q_s,
    q3: q_d,
    q4: q_c,
};

const GamePlayDetails = ({ player }) => {
    const API = process.env.REACT_APP_API_URL;
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [TotalPage, setTotalPage] = useState(0);
    const [TableData, setTableData] = useState({});
    const { PostRequest } = useHttp();
    const { ticketId, gameId, userId, game, drawTime, ticketTime, betPoint, winpoint, luckyNo, imageUrl, end_time, winCart } = player;
    const [loading, setLoading] = useState(false);

    const getBetHistory = async () => {
        setLoading(true);
        const { data } = await PostRequest(API + 'users/GetBetHistoryAdmin', {
            betId: ticketId
        });
        setLoading(false);
        if (data.responseCode === 1) {
            setTotalPage(Math.ceil(data.data.count / pageSize));
            setTableData(data.data);
        }
    };

    useEffect(() => {
        getBetHistory();
    }, []);

    const getCardImage = (winCart) => {
        return cardImageMap[winCart] || null;
    };

    return (
        <div className="p-4">
            <div className='h-15 mb-0' style={{ backgroundColor: '#30A5FF' }}>
                <h2 style={{ color: 'white' }} className="text-2xl font-semibold mb-0">Game Play Details</h2>
            </div>
            <table className="min-w-full bg-white border border-gray-200 mt-0">
                <thead>
                    <tr>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>Ticket ID</th>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>Game ID</th>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>Player</th>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>Game</th>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>Draw Time</th>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>Ticket Time</th>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>Bet</th>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>Won</th>
                        <th className="px-4 py-2 border" style={{ color: '#30A5FF' }}>LuckyNo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="even:bg-gray-100">
                        <td className="px-4 py-2 border text-center">{TableData.ticketId}</td>
                        <td className="px-4 py-2 border text-center">{TableData.gameId}</td>
                        <td className="px-4 py-2 border text-center">{TableData.userId}</td>
                        <td className="px-4 py-2 border text-center">JEETO JOKER</td>
                        <td className="px-4 py-2 border text-center">{new Date(TableData.updatedAt).toLocaleString('en-IN', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                        })}
                            {' '}
                            {new Date(TableData.updatedAt).toLocaleTimeString('en-IN', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                            })}</td>
                        <td className="px-4 py-2 border text-center">{new Date(ticketTime).toLocaleString('en-IN', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                        })}
                            {' '}
                            {new Date(ticketTime).toLocaleTimeString('en-IN', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                            })}</td>
                        <td className="px-4 py-2 border text-center">{betPoint}</td>
                        <td className="px-4 py-2 border text-center">{winpoint}</td>
                        <td className="px-4 py-2 border text-center">{70}</td>
                    </tr>
                    <tr className="even:bg-gray-100">
                        <td colSpan="9" className="px-4 py-2 border text-center">
                            {!imageUrl ? (
                                <img src={getCardImage(winCart)} alt="Game" className="mx-auto" />
                            ) : (
                                <div className="w-full h-32 bg-gray-200 flex items-center justify-center">
                                    Image Space
                                </div>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                {Object.keys(TableData).length > 0 && <BetDetails data={TableData} />}
            </div>
        </div>
    );
};

export default GamePlayDetails;
