import React from "react";
import { useLocation } from "react-router-dom";

const PageNameBar = () => {
  const location = useLocation();
  return (
    <>
      <div className="flex h-[50px] w-full items-center bg-[#30A5FF] pl-[20px] text-[20px] text-white">
        {location.pathname === "/jetto/dashboard" ? (
          <h3>Dashboard</h3>
        ) : location.pathname === "/jetto/Stokez" ? (
          <h3>Stokez</h3>
        ) : location.pathname === "/jetto/Agent" ? (
          <h3>Agents</h3>
        ) : location.pathname === "/jetto/Player" ? (
          <h3>Player</h3>
        ) : (
          "next part"
        )}
      </div>
    </>
  );
};

export default PageNameBar;
