import React, { useEffect, useState } from "react";
import useHttp from "Hooks/useHttp";
import { Link } from "react-router-dom";
const API = process.env.REACT_APP_API_URL;
const HomePage = () => {
  const { PostRequest } = useHttp();
  const [files, setFiles] = useState([]);
  const DownloadContent = async () => {
    const { data } = await PostRequest(API + "users/DownloadContent", {});
    if (data.responseCode === 1) {
      setFiles(data.responseData);
    }
  };

  useEffect(() => {
    DownloadContent();
  }, []);
  return (
    <div className="flex sm:h-screen items-center justify-center bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url('../assets/bg-new.jpg')` }}>
      
      <div id="wrapper" className="w-full sm:w-[747px] mx-auto mt-0 px-4 sm:px-0">
      
        <div className="Flash_Logo float-left mt-2.5 h-[80px] sm:h-[160px] w-[127px] sm:w-[254px]">
          <img src="../assets/logo.png" alt="KJ Logo" className="h-full w-auto" />
        </div>
        <div className="Web_InstructionBanner w-full sm:w-[780px] h-auto sm:h-[430px] relative top-[-10px]">
          <img src="../assets/Web_InstructionBanner.png" alt="Web Instruction Banner" className="w-full h-auto sm:h-[408px]" />
        </div>
        <div className="Buttons flex flex-col sm:flex-row justify-center relative sm:left-[10px] mt-3.5">
        {files.map((file, index) => (
                  <a key={index}
                    download
                    href={`${API}${file.file.filepath}`} 
                    className="inline-block mx-2 mb-2 sm:mb-0"
                  >
                    {file.file.type === "PC" && (
                      <img
                        src="../assets/btn-download-nor1.png"
                        alt="Download Button"
                        className="w-[140px] sm:w-[210px] h-[67px] sm:h-[100px] transition duration-300 hover:opacity-80"
                      />
                    )}
                    {file.file.type === "MOBILE" && (
                      <img
                        src="../assets/btn-instant-nor1.png"
                        alt="Download Button"
                        className="w-[140px] sm:w-[210px] h-[67px] sm:h-[100px] transition duration-300 hover:opacity-80"
                      />
                    )}
                    {file.file.type === "PCPRINT" && (
                      <img
                        src="../assets/download-v2.png"
                       alt="Download Button"
                        className="w-[140px] sm:w-[210px] h-[100px] sm:h-[80px] transition duration-300 hover:opacity-80"
                      />
                    )}
                  </a>
              ))}
        </div>
        <div className="banner18 w-[240px] sm:w-[373px] pt-0 mt-4 mx-auto">
          <img src="../assets/Web_18+banner.png" alt="18+ Banner" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
