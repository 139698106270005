import React, { useState, useEffect, useCallback } from "react";
import "tailwindcss/tailwind.css";
import useHttp from "Hooks/useHttp";
import androidImage from "../../../../icons/android.png";
import computerImage from "../../../../icons/computer.png";

// check the columns
const PlayerSecurity = () => {
  const [playerData, setPlayerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesToShow, setEntriesToShow] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const API = process.env.REACT_APP_API_URL;
  const { PostRequest } = useHttp();
  const [loggedInUser, setLoggedInUser] = useState("");
  const fetchData = useCallback(async () => {
    const userString = sessionStorage.getItem("user");
    if (userString) {
      const user = JSON.parse(userString);
      const userId = user.id;
      setLoggedInUser(user.username);
      const { data, error } = await PostRequest(`${API}users/DeviceSecurity`, {
        id: userId,
      });

      if (data) {
        setPlayerData(Array.isArray(data.data) ? data.data : []);
        setFilteredData(Array.isArray(data.data) ? data.data : []);
      } else {
        setError(error);
      }
      setLoading(false);
    } else {
      setError({ message: "User not found in local storage" });
      setLoading(false);
    }
  }, [API, PostRequest]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = playerData.filter((player) => {
      const username = player.username
        ? player.username.toString().toLowerCase()
        : "";
      const lastLoginIP = player.lastLoginIP
        ? player.lastLoginIP.toString().toLowerCase()
        : "";
      const approvedBy = player.approvedBy
        ? player.approvedBy.toString().toLowerCase()
        : "";
      const date = player.date ? player.date.toString().toLowerCase() : "";
      const term = searchTerm.toLowerCase();

      return (
        username.includes(term) ||
        lastLoginIP.includes(term) ||
        approvedBy.includes(term) ||
        date.includes(term)
      );
    });
    setFilteredData(filtered);
  }, [searchTerm, playerData]);

  const handleEntriesChange = (e) => {
    setEntriesToShow(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing the number of entries
  };

  const handleApprove = async (userId) => {
    const { data } = await PostRequest(API + "users/UpdateSecurityStatus", {
      userId,
      approvedByUsername: loggedInUser,
    });
    if (data.responseCode === 1) {
      setRefresh(true);
    }
  };

  useEffect(() => {
    if (refresh) {
      window.location.reload();
      setRefresh(false);
    }
  }, [refresh]);

  const indexOfLastEntry = currentPage * entriesToShow;
  const indexOfFirstEntry = indexOfLastEntry - entriesToShow;
  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const totalPages = Math.ceil(filteredData.length / entriesToShow);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <div className="p-4">
      <div className="mb-4 flex justify-between">
        <div>
          <label htmlFor="entriesToShow" className="mr-2">
            Show
          </label>
          <select
            id="entriesToShow"
            value={entriesToShow}
            onChange={handleEntriesChange}
            className="rounded border p-1"
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
          <span className="ml-2">entries</span>
        </div>
        <div>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="rounded border p-1"
          />
        </div>
      </div>
      <h2 className="mb-4 text-2xl font-semibold">Player Security</h2>
      <table className="min-w-full border border-gray-200 bg-white">
        <thead>
          <tr>
            <th className="border px-4 py-2">#</th>
            <th className="border px-4 py-2">Username</th>
            <th className="border px-4 py-2">Last Login IP</th>
            <th className="border px-4 py-2">Approved</th>
            <th className="border px-4 py-2">Approved By</th>
            <th className="border px-4 py-2">Date</th>
            <th className="border px-4 py-2">Approve</th>
          </tr>
        </thead>
        <tbody>
          {currentEntries.map((player, index) => (
            <tr key={index} className="even:bg-gray-100">
              <td
                className="border px-4 py-2 text-sm"
                style={{ marginLeft: "5px" }}
              >
                {indexOfFirstEntry + index + 1}
              </td>
              <td
                className="border px-4 py-2 text-sm"
                style={{ marginLeft: "5px" }}
              >
                <div className="flex gap-2">
                  {player.deviceType.toLowerCase() === "desktop" ||
                  player.deviceType === "" ? (
                    <img
                      src={computerImage}
                      alt="Computer Icon"
                      className="icon"
                      style={{ width: "24px", height: "24px" }}
                    />
                  ) : player.deviceType.toLowerCase() === "android" ? (
                    <img
                      src={androidImage}
                      alt="Android Icon"
                      className="icon"
                      style={{ width: "24px", height: "24px" }}
                    />
                  ) : null}
                  {player.username}
                </div>
              </td>
              <td
                className="border px-4 py-2 text-sm"
                style={{ marginLeft: "5px" }}
              >
                {player.deviceId}
              </td>
              <td className="border px-4 py-2">
                <span
                  className={`inline-block h-6 w-20 rounded text-sm ${
                    player.approvedBy
                      ? "bg-[#A0DF46] hover:bg-[#bee686]"
                      : "bg-[#F72540] hover:bg-[#af505d]"
                  }`}
                  style={{ marginLeft: "5px" }}
                ></span>
              </td>
              <td
                className="border px-4 py-2 text-sm"
                style={{ marginLeft: "5px" }}
              >
                {player.approvedBy}
              </td>
              <td className="border px-4 py-2">
                <span style={{ marginLeft: "5px" }}>
                  {new Date(player.updatedAt).toLocaleString("en-US", {
                    month: "short", // Abbreviated month name (e.g., May)
                    day: "numeric", // Day of the month with padding (e.g., 24)
                    year: "numeric", // Year with padding (e.g., 2024)
                  })}{" "}
                  {/* Add a space between date and time */}
                  {new Date(player.updatedAt).toLocaleTimeString("en-US", {
                    hour: "numeric", // Hour with padding (e.g., 02)
                    minute: "numeric", // Minute with padding (e.g., 12)
                    hour12: true, // Use 12-hour clock (e.g., AM/PM)
                  })}
                </span>
              </td>
              <td className="border px-4 py-2 text-center">
                <button
                  onClick={() => handleApprove(player.userId)}
                  className="rounded bg-blue-500 px-4 py-2 text-sm text-white hover:bg-blue-700"
                  style={{ width: "100px" }}
                >
                  {player.approvedBy ? "Approved" : "Approve"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="mr-2 rounded bg-gray-300 px-4 py-2 text-gray-700"
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="rounded bg-gray-300 px-4 py-2 text-gray-700"
        >
          Next
        </button>
      </div>
    </div>
  );
};
export default PlayerSecurity;

// on clicking turnover, redirect to turnover page, and on clicking dashboard, redirect to dashboard page -DONE
// Year bug in player history and turnover - DONE
// device security page, give all left margin, add shining color on green and red, and fix the button size of approve - DONE
// login page customize -DONE
// pagination
// remove that only part scrolling in Player Details, and make it full scrolable - DONE
