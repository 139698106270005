import React, { useState, useEffect } from "react";

function Clock({ show }) {
  const nDate = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Calcutta",
  });
  const [time, setTime] = useState(new Date(nDate));
  const [DateStamp, setDateStamp] = useState(null);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    const nDate = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Calcutta",
    });
    const dateObj = new Date(nDate);
    const lMonth = dateObj.toLocaleString("default", { month: "long" });
    // const sMonth = dateObj.toLocaleString("default", { month: "short" });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    let dateString = day + " " + lMonth + " " + year;
    // let cleanedString = dateString.replace(/"/g, "");
    setDateStamp(dateString);
  }, []);
  const formatTime = (date) => {
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${formatDigit(hours)}:${formatDigit(minutes)}:${formatDigit(
      seconds
    )}`;
  };

  const formatDigit = (digit) => {
    return digit < 10 ? `0${digit}` : digit;
  };

  return (
    <div className="w-full h-full rounded-lg bg-header-headbg flex justify-center items-center">
      <div><p className="text-xl font-black text-white  sm-max:text-xs">
        {DateStamp}
      </p>
      <p className="text-[30px] font-black text-white   sm-max:text-xs">
        {formatTime(time)}
      </p></div>
    </div>
  );
}

export default Clock;
