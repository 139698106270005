import React from "react";
import Chart from "chart.js/auto";

import { Line } from "react-chartjs-2";

const labels = ["January", "February", "March", "April", "May", "June"];

const data = {
  labels: labels,
  datasets: [
    {
      label: "My First dataset",
      backgroundColor: "rgb(255, 99, 132)",
      borderColor: "rgb(255, 99, 132)",
      data: [0, 10,20,27,41,50],
    },
  ],
};

const DashboardChart = () => {
  return (
    // <div style={{height:"500px",width:"5000px"}}>
    <div>
      <Line data={data} />
    </div>
  );
};

export default DashboardChart; // Exporting the LineChart component as the default export of the module
