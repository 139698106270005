import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { columnsTurnoverReport } from "./variables/columnsData";
import TurnoverReport from "./components/TurnoverReport";
import SelectField from "components/fields/SelectField";
import Pagination from "components/Pagination/Pagination";
import routes from "routes";
import useHttp from "../../../Hooks/useHttp";
const API = process.env.REACT_APP_API_URL;
const TurnoverReportTables = () => {
  const navigate = useNavigate();
  const Data = sessionStorage.getItem("user");
  const LoginUser = JSON.parse(Data);
  const userType = LoginUser?.userType;
  const [loading, setLoading] = useState(false);
  const [UserId, setUserId] = useState("");
  const [Type, setType] = useState("");
  const [TableData, setTableData] = useState([]);
  const [FromDate, setFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [ToDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const { PostRequest } = useHttp();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalPage, setTotalPage] = useState(0);
  const [currentRoute, setCurrentRoute] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [selectedFromDateDay, setSelectedFromDateDay] = useState("");
  const [selectedFromDateMonth, setSelectedFromDateMonth] = useState("");
  const [selectedFromDateYear, setSelectedFromDateYear] = useState("");
  const [selectedGameName, setSelectedGameName] = useState("");
  const initialPayload = {
    STO: "",
    Agent: "",
    Player: "",
    Admin: true,
    page: 1,
    pageSize: 10,
    startDate: "",
    endDate: "",
  };

  const [previousPayload, setPreviousPayload] = useState(initialPayload);
  const [currentPayload, setCurrentPayload] = useState(initialPayload);

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based, so add 1
    const year = currentDate.getFullYear(); // Get current year

    setSelectedFromDateDay(currentDay.toString());
    setSelectedFromDateMonth(currentMonth.toString());
    setSelectedFromDateYear(year.toString());
    setSelectedGameName("Today");
    setSelectedDay(currentDay.toString());
    setSelectedMonth(currentMonth.toString());
    setSelectedYear(year.toString());
    setCurrentYear(year.toString()); // Update currentYear state
  }, []);

  const handleDateOptionChange = (option) => {
    const currentDate = new Date();
    let fromDateDay = "";
    let fromDateMonth = "";
    let fromDateYear = "";
    let toDateDay = "";
    let toDateMonth = "";
    let toDateYear = "";

    switch (option) {
      case "Today":
        fromDateDay = currentDate.getDate().toString();
        fromDateMonth = (currentDate.getMonth() + 1).toString();
        fromDateYear = currentDate.getFullYear().toString();
        toDateDay = fromDateDay;
        toDateMonth = fromDateMonth;
        toDateYear = fromDateYear;
        break;
      case "Yesterday":
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
        fromDateDay = yesterday.getDate().toString();
        fromDateMonth = (yesterday.getMonth() + 1).toString();
        fromDateYear = yesterday.getFullYear().toString();
        toDateDay = fromDateDay;
        toDateMonth = fromDateMonth;
        toDateYear = fromDateYear;
        break;
      case "This Week":
        const firstDayOfWeek = new Date(
          currentDate.setDate(
            currentDate.getDate() -
              (currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1)
          )
        );
        const lastDayOfWeek = new Date(
          currentDate.setDate(firstDayOfWeek.getDate() + 6)
        );
        fromDateDay = firstDayOfWeek.getDate().toString();
        fromDateMonth = (firstDayOfWeek.getMonth() + 1).toString();
        fromDateYear = firstDayOfWeek.getFullYear().toString();
        toDateDay = lastDayOfWeek.getDate().toString();
        toDateMonth = (lastDayOfWeek.getMonth() + 1).toString();
        toDateYear = lastDayOfWeek.getFullYear().toString();
        break;
      case "Last Week":
        const previousWeekStart = new Date(
          currentDate.setDate(
            currentDate.getDate() -
              (currentDate.getDay() === 0 ? 13 : currentDate.getDay() + 6)
          )
        );
        const previousWeekEnd = new Date(
          currentDate.setDate(previousWeekStart.getDate() + 6)
        );
        // const previousWeekStart = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() - 7));
        // const previousWeekEnd = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6));
        fromDateDay = previousWeekStart.getDate().toString();
        fromDateMonth = (previousWeekStart.getMonth() + 1).toString();
        fromDateYear = previousWeekStart.getFullYear().toString();
        toDateDay = previousWeekEnd.getDate().toString();
        toDateMonth = (previousWeekEnd.getMonth() + 1).toString();
        toDateYear = previousWeekEnd.getFullYear().toString();
        break;
      case "This Month":
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );
        fromDateDay = firstDayOfMonth.getDate().toString();
        fromDateMonth = (firstDayOfMonth.getMonth() + 1).toString();
        fromDateYear = firstDayOfMonth.getFullYear().toString();
        toDateDay = lastDayOfMonth.getDate().toString();
        toDateMonth = (lastDayOfMonth.getMonth() + 1).toString();
        toDateYear = lastDayOfMonth.getFullYear().toString();
        break;
      case "Last Month":
        const prevDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          1
        );
        const prevLastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          0
        );
        console.log(prevLastDayOfMonth);
        fromDateDay = prevDayOfMonth.getDate().toString();
        fromDateMonth = (prevDayOfMonth.getMonth() + 1).toString();
        fromDateYear = prevDayOfMonth.getFullYear().toString();
        toDateDay = prevLastDayOfMonth.getDate().toString();
        toDateMonth = (prevLastDayOfMonth.getMonth() + 1).toString();
        toDateYear = prevLastDayOfMonth.getFullYear().toString();
        break;
      default:
        break;
    }

    setSelectedFromDateDay(fromDateDay);
    setSelectedFromDateMonth(fromDateMonth);
    setSelectedFromDateYear(fromDateYear);
    setSelectedDay(toDateDay);
    setSelectedMonth(toDateMonth);
    setSelectedYear(toDateYear);
  };
  // Helper function to format date components into YYYY-MM-DD format

  // Function to combine individual date components into a single date string
  const combineDateComponents = (year, month, day) => {
    const paddedMonth = month < 10 ? `0${month}` : `${month}`;
    const paddedDay = day < 10 ? `0${day}` : `${day}`;
    // return `${paddedDay}-${paddedMonth}-${year}`;
    return `${year}-${paddedMonth}-${paddedDay}`;
  };

  let startDate;
  let endDate;
  startDate =
    selectedFromDateYear && selectedFromDateMonth && selectedFromDateDay
      ? combineDateComponents(
          selectedFromDateYear,
          selectedFromDateMonth,
          selectedFromDateDay
        )
      : "";

  endDate =
    selectedYear && selectedMonth && selectedDay
      ? combineDateComponents(selectedYear, selectedMonth, selectedDay)
      : "";

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getTurnoverReport = async () => {
    const Data = sessionStorage.getItem("user");
    const LoginUser = JSON.parse(Data);
    let STO = "";
    let Agent = "";
    let Player = "";
    let Admin = false;

    if (LoginUser?.userType === "STO") {
      STO = LoginUser?.id;
    }
    if (LoginUser?.userType === "Agent") {
      Agent = LoginUser?.id;
    }
    if (LoginUser?.userType === "Player") {
      Player = LoginUser?.id;
    }
    if (LoginUser?.userType === "Admin") {
      Admin = true;
    }
    if (UserId) {
      if (Type === "STO") {
        STO = UserId;
        Agent = "";
        Player = "";
        Admin = false;
      }
      if (Type === "Agent") {
        STO = "";
        Agent = UserId;
        Player = "";
        Admin = false;
      }
      if (Type === "Player") {
        STO = "";
        Agent = "";
        Player = UserId;
        Admin = false;
      }
    }
    if (startDate === "" && endDate === "") {
      const today = new Date();
      endDate = getFormattedDate(today);
      startDate = endDate;
    }
    const FormData = {
      STO,
      Agent,
      Player,
      Admin,
      page,
      pageSize,
      startDate,
      endDate,
    };
    setFromDate(startDate);
    setToDate(endDate);
    setPreviousPayload(currentPayload);
    setCurrentPayload(FormData);
    try {
      setLoading(true);
      const { data } = await PostRequest(API + "bets/TurnoverReport", FormData);
      // Filter STOs where PlayPoint is greater than 0
      setLoading(false);
      if (data.responseCode !== 0) {
        setTableData(data.responseData);
        setTotalPage(Math.ceil(data.responseData.length / pageSize) || 0);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTurnoverReport();
  }, [location, page, pageSize, UserId]);

  const reinitializePage = (data) => {
    // Assuming data.responseData is an array of objects
    // and each object has properties that correspond to your state variables
    setTableData(data.responseData || []);
    setTotalPage(Math.ceil(data.responseData.count / pageSize) || 0);
    // Add more set functions as needed
  };

  const handleGraphRedirect = () => {
    if (LoginUser.userType === "Admin") {
      navigate(
        `/jetto/graph?userType=${LoginUser.userType}&userId=${LoginUser.username}`
      );
    }
  };

  const handleBackLogic = async () => {
    // Use the previousPayload for API call
    const FormData = previousPayload;

    try {
      setLoading(true);
      const { data } = await PostRequest(API + "bets/TurnoverReport", FormData);
      setLoading(false);
      setTableData(data.responseData || []);
      setTotalPage(Math.ceil(data.responseData.count / pageSize) || 0);

      // Set currentPayload to the previousPayload after successful API call
      setCurrentPayload(previousPayload);
      reinitializePage(data);
    } catch (error) {
      setLoading(false);
    }
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
        break;
      }
    }
    return activeRoute;
  };

  useEffect(() => {
    getActiveRoute(routes);
  }, [location]);

  return (
    <div className="rounded-[12px] bg-white p-[10px]">
      <div className="mt-5 min-w-fit">
        <div className="top-0 z-10 bg-white">
          <div className="mb-5 flex items-center justify-between pt-[20px]">
            <div className="w-full bg-blue-500 p-2 text-xl font-bold text-white">
              {currentRoute}
            </div>
          </div>
          <div className="mb-3 space-y-0">
            <div className=" mr-3  flex items-center align-top">
              <label
                htmlFor="Stokez"
                className="text-black-700 mt-2 mr-2 block text-sm font-black"
              >
                Select Stokez:
              </label>
              <SelectField
                style={{
                  borderColor: "black",
                  marginTop: "10px",
                  marginBottom: "5px",
                  height: "30px",
                  borderRadius: 0,
                  padding: "0 5px",
                }}
                variant="auth"
                id="Stokez"
                extra=""
                label=""
                onChange={(e) => {}}
              >
                <option value="">Select Stokez</option>
              </SelectField>
            </div>
            <div className=" flex items-center align-top">
              <label
                htmlFor="GameName"
                className="text-black-700 mt-2 mr-2 block text-sm font-black"
              >
                Game Name:
              </label>
              <SelectField
                style={{
                  borderColor: "black",
                  marginTop: "10px",
                  marginBottom: "5px",
                  height: "30px",
                  width: "500px",
                  borderRadius: 0,
                  padding: "0 5px",
                }}
                variant="auth"
                id="GameName"
                extra=""
                label=""
                onChange={(e) => {}}
              >
                <option value="">Triple Chance</option>
              </SelectField>
            </div>
            <div className=" flex items-center align-top">
              <label
                htmlFor="FromDate"
                className="text-black-700 mt-2 mr-2 block text-sm font-black"
              >
                From Date:
              </label>
              <div className="flex space-x-2 align-top">
                <SelectField
                  style={{
                    padding: "0 5px",
                    borderColor: "black",
                    marginTop: "10px",
                    marginBottom: "5px",
                    height: "30px",
                    width: "100px",
                    borderRadius: 0,
                  }}
                  id="FromDateDay"
                  variant="auth" // Assuming variant should be applied
                  value={selectedFromDateDay}
                  onChange={(e) => setSelectedFromDateDay(e.target.value)}
                >
                  {[...Array(31)].map((_, index) => (
                    <option key={index + 1} value={(index + 1).toString()}>
                      {index + 1}
                    </option>
                  ))}
                </SelectField>

                <SelectField
                  style={{
                    padding: "0 5px",
                    borderColor: "black",
                    marginTop: "10px",
                    marginBottom: "5px",
                    height: "30px",
                    width: "100px",
                    borderRadius: 0,
                  }}
                  id="FromDateMonth"
                  variant="auth" // Assuming variant should be applied
                  value={selectedFromDateMonth}
                  onChange={(e) => setSelectedFromDateMonth(e.target.value)}
                >
                  {[...Array(12)].map((_, index) => (
                    <option key={index + 1} value={(index + 1).toString()}>
                      {index + 1}
                    </option>
                  ))}
                </SelectField>

                <SelectField
                  style={{
                    padding: "0 5px",
                    borderColor: "black",
                    marginTop: "10px",
                    marginBottom: "5px",
                    height: "30px",
                    width: "100px",
                    borderRadius: 0,
                  }}
                  id="FromDateYear"
                  variant="auth" // Assuming variant should be applied
                  value={selectedFromDateYear}
                  onChange={(e) => setSelectedFromDateYear(e.target.value)}
                >
                  {[...Array(25)].map((_, index) => {
                    const year = currentYear - index;
                    return (
                      <option key={year} value={year.toString()}>
                        {year}
                      </option>
                    );
                  })}
                </SelectField>
                <SelectField
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderColor: "black",
                    marginTop: "10px",
                    marginBottom: "5px",
                    height: "30px",
                    width: "100px",
                    borderRadius: 0,
                    padding: "0 5px", // Add padding to prevent the text from being cut off
                  }}
                  variant="auth"
                  id="GameName"
                  extra=""
                  label=""
                  onChange={(e) => {
                    setSelectedGameName(e.target.value);
                    handleDateOptionChange(e.target.value); // Call handleDateOptionChange here
                  }}
                  value={selectedGameName}
                >
                  <option value="Today">Today</option>
                  <option value="Yesterday">Yesterday</option>
                  <option value="This Week">This Week</option>
                  <option value="Last Week">Last Week</option>
                  <option value="This Month">This Month</option>
                  <option value="Last Month">Last Month</option>
                </SelectField>
              </div>
            </div>

            <div className=" flex items-center align-top">
              <label
                htmlFor="ToDate"
                className="text-black-700 mt-2 ml-4 mr-2 block text-sm font-black"
              >
                To Date:
              </label>
              <div className="flex space-x-2">
                <SelectField
                  style={{
                    padding: "0 5px",
                    borderColor: "black",
                    marginTop: "10px",
                    marginBottom: "5px",
                    height: "30px",
                    width: "100px",
                    borderRadius: 0,
                  }}
                  id="ToDateDay"
                  variant="auth" // Assuming variant should be applied
                  value={selectedDay}
                  onChange={(e) => setSelectedDay(e.target.value)}
                >
                  {[...Array(31)].map((_, index) => (
                    <option key={index + 1} value={(index + 1).toString()}>
                      {index + 1}
                    </option>
                  ))}
                </SelectField>
                <SelectField
                  style={{
                    padding: "0 5px",
                    borderColor: "black",
                    marginTop: "10px",
                    marginBottom: "5px",
                    height: "30px",
                    width: "100px",
                    borderRadius: 0,
                  }}
                  id="ToDateMonth"
                  variant="auth" // Assuming variant should be applied
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  {[...Array(12)].map((_, index) => (
                    <option key={index + 1} value={(index + 1).toString()}>
                      {index + 1}
                    </option>
                  ))}
                </SelectField>
                <SelectField
                  style={{
                    padding: "0 5px",
                    borderColor: "black",
                    marginTop: "10px",
                    marginBottom: "5px",
                    height: "30px",
                    width: "100px",
                    borderRadius: 0,
                  }}
                  id="ToDateYear"
                  variant="auth" // Assuming variant should be applied
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  {[...Array(25)].map((_, index) => {
                    const year = currentYear - index;
                    return (
                      <option key={year} value={year.toString()}>
                        {year}
                      </option>
                    );
                  })}
                </SelectField>
              </div>
            </div>
          </div>
          <div className="mt-3 flex justify-between px-5">
            <button
              type="button"
              onClick={getTurnoverReport}
              style={{
                color: "white",
                borderRadius: 5,
                backgroundColor: "#30A5FF",
              }}
              className="mb-8 flex h-10 items-center justify-center rounded-xl px-4 outline-none"
            >
              Search
            </button>
            <div className="flex space-x-2">
              <button
                type="button"
                onClick={handleBackLogic}
                style={{
                  color: "white",
                  borderRadius: 5,
                  backgroundColor: "#30A5FF",
                }}
                className="mb-8 flex h-10 items-center justify-center rounded-xl px-4 outline-none"
              >
                Back
              </button>
              {LoginUser.userType === "Admin" && (
                <button
                  type="button"
                  onClick={handleGraphRedirect}
                  style={{
                    color: "white",
                    borderRadius: 5,
                    backgroundColor: "#30A5FF",
                  }}
                  className="mb-8 flex h-10 items-center justify-center rounded-xl px-4 outline-none"
                >
                  Graph
                </button>
              )}
            </div>
          </div>
        </div>
        <TurnoverReport
          columnsData={columnsTurnoverReport}
          tableData={TableData}
          pageSize={pageSize}
          setUserId={setUserId}
          setType={setType}
          selectedFromDate={FromDate}
          selectedToDate={ToDate}
        />
        <Pagination
          totalPage={TotalPage}
          current={page}
          pageSize={pageSize}
          setPage={setPage}
        />
      </div>
    </div>
  );
};

export default TurnoverReportTables;
